<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
        <ng-container>
            <div class="modal-header align-items-center py-0">
                <span class="icon icon-cirlce">
                    <img class="icon-light" src="../assets/images/header/phone-icon.png" alt="" aria-hidden="true" width="44" />
                    <img class="icon-dark" src="../assets/images/header/phone-icon-dark.png" alt="" aria-hidden="true" width="44" />
                </span>
                <h6 class="modal-title w-100 text-center text-uppercase" id="modal-basic-title">
                    {{'Call'|translate}}
                </h6>
                <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- caller.component.html -->
            <div class="modal-body p-0">
                <div class="form-block-1 p-3">
                    <form [formGroup]="phoneForm" (ngSubmit)="onSubmit()">
                        <div class="card-form-content card-form-success mb-4" *ngIf="loanID">
                        <select class="form-control form-control-sm " (change)="onPhoneSelect($event)">
                            <!-- Iterate over each contact and list their phone types -->
                            <ng-container *ngFor="let contact of contactList">
                                <optgroup class="label" [label]="contact.applicant_type | titlecase">
                                    <!-- Mobile Phone -->
                                <option 
                                    *ngIf="contact.mobile_phone" 
                                    [value]="contact.id + '-mobile'" 
                                    [selected]="selectedApplicantId === contact.id && selectedPhoneNumber === contact.mobile_phone" 
                                    [ngClass]="{'dnc-checked': (loanDetail.dnc_list_mobile_calling_a1 === 'TRUE' && contact.applicant_type === 'APPLICANT') || 
                                    (loanDetail.dnc_list_mobile_calling_a2 === 'TRUE' && contact.applicant_type === 'CO-APPLICANT') || 
                                    (loanDetail.dnc_list_mobile_calling_c1 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-1') || 
                                    (loanDetail.dnc_list_mobile_calling_c2 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-2') ||
                                    (loanDetail.dnc_list_mobile_calling_c === 'TRUE' && contact.applicant_type === 'COMPANY') || 
                                    (loanDetail.dnc_list_mobile_calling_r === 'TRUE' && contact.applicant_type === 'RECIPIENT') }">
                                        {{ formatName(contact) }} (Mobile Phone)
                                </option>
                            
                                <!-- Home Phone -->
                                <option 
                                    *ngIf="contact.home_phone" 
                                    [value]="contact.id + '-home'" 
                                    [selected]="selectedApplicantId === contact.id && selectedPhoneNumber === contact.home_phone"
                                    [ngClass]="{'dnc-checked': loanDetail.dnc_list_home_phone_calling_a1 === 'TRUE' && contact.applicant_type === 'APPLICANT' ||
                                        loanDetail.dnc_list_home_phone_calling_a2 === 'TRUE' && contact.applicant_type === 'CO-APPLICANT' ||
                                        loanDetail.dnc_list_home_phone_calling_c1 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-1' ||
                                        loanDetail.dnc_list_home_phone_calling_c2 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-2' ||
                                        loanDetail.dnc_list_home_phone_calling_c === 'TRUE' && contact.applicant_type === 'COMPANY' ||
                                        loanDetail.dnc_list_home_phone_calling_r === 'TRUE' && contact.applicant_type === 'RECIPIENT' }">
                                    {{ formatName(contact) }} (Home Phone)
                                </option>
                    
                                <!-- Work Phone -->
                                <option 
                                    *ngIf="contact.work_phone" 
                                    [value]="contact.id + '-work'" 
                                    [selected]="selectedApplicantId === contact.id && selectedPhoneNumber === contact.work_phone"
                                    [ngClass]="{'dnc-checked': loanDetail.dnc_list_work_phone_calling_a1 === 'TRUE' && contact.applicant_type === 'APPLICANT' ||
                                        loanDetail.dnc_list_work_phone_calling_a2 === 'TRUE' && contact.applicant_type === 'CO-APPLICANT' ||
                                        loanDetail.dnc_list_work_phone_calling_c1 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-1' ||
                                        loanDetail.dnc_list_work_phone_calling_c2 === 'TRUE' && contact.applicant_type === 'CO-SIGNER-2' ||
                                        loanDetail.dnc_list_work_phone_calling_c === 'TRUE' && contact.applicant_type === 'COMPANY' ||
                                        loanDetail.dnc_list_work_phone_calling_r === 'TRUE' && contact.applicant_type === 'RECIPIENT'}">
                                    {{ formatName(contact) }} (Work Phone)
                                </option>
                            </optgroup>
                            </ng-container>
                        </select>
                        <div class="text-danger" *ngIf="isDNC">
                            {{'Selected number is in DNC'|translate}}.
                        </div>
                        </div>
                        <div class="card-form-content card-form-success mb-4" *ngIf="!loanID">
                            <div class="row gutters-5 my-3">
                                <div class="form-group col-lg-5">
                                    <label class="form-group-label">{{'Loan ID'|translate}}</label>
                                </div>
                                <div class="form-group col-lg-7">
                                    <input
                                    formControlName="loan_id"
                                    name="loan_id"
                                    type="text"
                                    class="form-control form-control-sm"
                                    value=""
                                    />
                                    <val-errors controlName="loan_id">
                                        <ng-template valError="pattern">{{'Loan ID is not well formed'|translate}}.</ng-template>
                                        <ng-template valError="maxlength"
                                            >{{'Loan ID cannot be longer than 100 characters'|translate}}.</ng-template
                                        >
                                        <ng-template valError="minlength"
                                            >{{'Loan ID should be more than 2 characters'|translate}}.</ng-template
                                        >
                                        </val-errors>
                        
                                </div>
                            </div>
                            <div class="row gutters-5 my-3">
                                <div class="form-group col-lg-5">
                                    <label class="form-group-label field-required">{{'Name'|translate}}</label>
                                </div>
                                <div class="form-group col-lg-7">
                                    <input
                                    formControlName="name"
                                    name="name"
                                    type="text"
                                    class="form-control form-control-sm"
                                    value=""
                                    />
                                    <val-errors controlName="name">
                                        <ng-template valError="required">{{"Name is required"|translate}}.</ng-template>
                                    </val-errors>
                                </div>
                            </div>
                        </div>
                        <div class="phone">

                            <div class="phone-keys">
                                <input type="text" class="phone-screen" [mask]="US_PHONE_MASKING"
                                formControlName="selectedPhoneNumber" [value]="selectedPhoneNumber | mask: US_PHONE_MASKING" placeholder="(000) 000-0000" />
                                <button type="button" class="action btn" [disabled]="isDNC || phoneForm.invalid || disableCallButton"  (click)="phoneCallStart()">
                                    {{"Call" | translate}}
                                </button>
                                <!-- <button type="button" class="operator btn disabled">{{"Mute"|translate}}</button>
                                <button type="button" class="operator btn disabled">{{"Hold"|translate}}</button>
                                <button type="button" class="operator btn disabled">{{"Transfer"|translate}}</button>
                                <button type="button" class="operator btn disabled">{{"Line 1"|translate}}</button>
                                <button type="button" class="operator btn disabled">{{"Line 2"|translate}}</button>
                                <button type="button" class="operator btn disabled">{{"To Account"|translate}}</button> -->
                            </div>
                            <val-errors controlName="selectedPhoneNumber">
                                <ng-template valError="required">{{"Number is required"|translate}}.</ng-template>
                                <ng-template valError="invalidPhoneNumber"
                                >{{'Number is not well formed'|translate}}.</ng-template
                            >
                            </val-errors>
                        
                        </div> 
                    </form>
                </div>
                <div class="form-block-2 p-3">
                    <form #f="ngForm" [formGroup]="transferCallForm" novalidate>
                        <div class="card-form-content card-form-success mb-2">
                            <h6 class="modal-title modal-title-success">Transfer Call</h6>
                            <div class="row gutters-5 mt-3">
                                <div class="form-group col-auto">
                                    <div class="form-check mt-1">
                                        <input class="form-check-input" type="radio" name="transferOption" id="agentRadio" value="agent"
                                        [checked]="transferCallForm.controls.transferOption.value === 'agent'" (change)="onRadioChange('agent')">
                                        <label class="form-check-label" for="agentRadio"></label>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">{{'Select Agent' | translate}}</label>
                                </div>
                                <div class="form-group col-lg-7 caller-select-box">
                                    <ng-select
                                      [items]="agentsList" 
                                      bindLabel="agent_name" 
                                      bindValue="id" 
                                      [searchable]="true"
                                      formControlName="agent_id" 
                                      [clearable]="true"
                                      [disabled]="isAgentDisabled"
                                      placeholder="{{'-Select-' | translate}}"
                                      class="form-control form-control-sm">
                                    </ng-select>
                                    <val-errors controlName="agent_id">
                                        <ng-template valError="required" class="pt-2">{{'This field is required' | translate}}</ng-template>
                                    </val-errors>
                                </div>
                            </div>
                            <div class="row gutters-5">
                                <div class="form-group col-auto">
                                    <div class="form-check mt-1">
                                        <input class="form-check-input" type="radio" name="transferOption" id="phoneRadio"
                                               value="phone" (change)="onRadioChange('phone')">
                                        <label class="form-check-label" for="phoneRadio"></label>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">{{'Phone Number' | translate}}</label>
                                </div>
                                <div class="form-group col-lg-7">
                                    <input type="text" formControlName="phone_number" [mask]="US_PHONE_MASKING" name="phone_number"
                                           class="form-control form-control-sm" [disabled]="isPhoneDisabled" />
                                
                                    <val-errors controlName="phone_number">
                                        <ng-template valError="required">{{'This field is required' | translate}}.</ng-template>
                                        <ng-template valError="invalidPhoneNumber">{{'Phone Number is not well formed' | translate}}.</ng-template>
                                    </val-errors>
                                </div>
                            </div>
                            <div class="row gutters-5">
                                <div class="form-group col-auto">
                                    <div class="form-check mt-1">
                                        <input class="form-check-input" type="radio" name="transferOption" id="extRadio" value="ext"
                                               (change)="onRadioChange('ext')">
                                        <label class="form-check-label" for="extRadio"></label>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label">{{'Phone Extension' | translate}}</label>
                                </div>
                                <div class="form-group col-lg-7">
                                    <input type="text" formControlName="phone_extension" name="phone_extension"
                                           class="form-control form-control-sm" [disabled]="isExtDisabled" />
                                
                                    <val-errors controlName="phone_extension">
                                        <ng-template valError="required">{{'This field is required' | translate}}.</ng-template>
                                        <ng-template valError="pattern">{{'Invalid Value' | translate}}.</ng-template>
                                        <ng-template valError="maxlength">{{'Phone Extension cannot be longer than 6 characters' | translate}}.</ng-template>
                                    </val-errors>
                                </div>
                            </div>
                
                        </div>
                
                        <div class="phone">
                            <p class="text-right my-1 pr-2" style="font-size: 14px;">Transferring call to {{transferringMsg}}.</p>
                            <div class="phone-keys">
                                <input type="text" class="phone-screen invisible" />
                                <button type="button" class="action btn" (click)="onClickTransferCall()" [disabled]="this.transferCallForm.invalid">
                                    {{"Transfer Call" | translate}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                
                <div class="form-block-3 p-3">
                    <form #f="ngForm" [formGroup]="addActivityForm" novalidate>
                        <div class="card-form-content card-form-success">
                            <div class="row gutters-5 mb-3">
                            <div class="form-group col-lg-5">
                                <label class="form-group-label field-required">{{'Activity Type'|translate}}</label>
                            </div>
                            <div class="form-group col-lg-7">
                                <select formControlName="code"
                                name="code" id="code"
                                class="form-control form-control-sm"
                                (change)="getSelectedItem($event)"
                                >
                                <option value="null">{{'-Select-'|translate}}</option>
                                <option *ngFor="let item of activityTypes" [ngValue]="item.cod_act">
                                    {{ item.description }}
                                </option>
                                </select>
                            </div>
                            <val-errors controlName="code">
                                <ng-template valError="required" class="pt-2">{{'Activity Type is required'|translate}}</ng-template>
                            </val-errors>
                            </div>
                    
                            <div class="row gutters-5">
                            <div class="form-group col-lg-12">
                                <label class="form-group-label form-group-new field-required">{{'Activity Note' | translate}}</label>
                            </div>
                            <div class="form-group col-lg-12">
                                <textarea
                                name="comments"
                                formControlName="comments"
                                (change)="onNoteValueChange($event)"
                                class="form-control"
                                rows="5"
                                height="200px"
                                ></textarea>
                                
                                <span class="text-count">
                                {{ addActivityForm.controls.comments.value?.length ?? 0 | number:'1.0-0' }} / 10,000
                                </span>
                                <val-errors controlName="comments" >
                                <ng-template valError="maxlength" class="pt-2">
                                    {{"Activity Note can't be greater than 10,000 characters"|translate}}.
                                </ng-template>
                                <ng-template valError="required" class="pt-2">
                                {{ 'Activity Note is required' | translate }}
                                </ng-template>
                            </val-errors>
                            </div>
                            </div>
                            <div *ngIf="isAcivityPTPActive">
                            <div class="row gutters-5 mt-3">
                            <div class="col-lg-6">
                                <div class="row">
                                <div class="form-group col-lg-4">
                                    <label class="form-group-label oflow">{{'PTP Date'|translate}}</label>
                                </div>
                                <div class="form-group col-lg-7">
                                    <app-date-picker formControlName="ptp_date"  #ptpdate name="ptp_date"  (dateSelected)="futureDateCheck(ptpdate.value)"></app-date-picker>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                <div class="form-group col-lg-5">
                                    <label class="form-group-label oflow">{{'PTP Amount'|translate}}</label>
                                </div>
                                <div class="form-group col-lg-7">
                                    <input type="text" name="ptp_amount" formControlName="ptp_amount"
                                    class="form-control form-control-sm" value=""
                                    prefix="$"
                                    mask="separator.2"
                                    thousandSeparator=","
                                    (change)="getAmount($event,1)"
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                            <span class="text-danger d-block" *ngIf="errormsg!=''">
                                {{errormsg}}
                            </span>
                            <val-errors controlName="ptp_date">
                                <ng-template valError="invalidPTPDate" class="d-block font-size-14 lms-text-light-pink"> {{'PTP Date must be in the future.'|translate}} </ng-template>
                                <ng-template valError="required">{{'PTP Date is required'|translate}}</ng-template>
                            </val-errors>
                            </div>
                            <div class="row pt-4"><div class="col-md-7"></div>
                            <div class="col-md-5 text-right">
                            <button type="submit" class="btn btn-success-light" [disabled]="disableHangupAndEndCallButton" (click)="callHangupAndPostdisposition()" >
                            {{'End Call and Post Note'|translate}}
                            </button> 
                            </div>
                        </div>
                        </div> 
                    </form> 
                </div>
            </div>
        </ng-container>
    </div>
</div>