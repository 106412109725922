<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content ">
    <!-- Reamortize Loan Modal -->
    <form #f="ngForm" [formGroup]="form" novalidate>
      <div class="modal-header align-items-center cursor-pointer" cdkDragHandle >
        <h6 class="modal-title modal-title-success">{{'Reamortize Loan'|translate}}</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="lms-module-card">
          <div class="card-body">
            <div class="card-form-content card-form-success">
              <div class="bg-note-info p-2 mb-3">
                {{'Input the field values for reamortizing the loan'|translate}}.
              </div>
              <div class="table-responsive">
                <table class="table table-sm table-no-bg table-align-middle mb-0 reamortizing-table-wrapper">
                  <thead>
                    <tr class="header">
                      <td class="border-0">{{'Field Name'|translate}}</td>
                      <td class="border-0">{{'Before'|translate}}</td>
                      <td class="border-0"></td>
                      <td class="border-0">{{'After'|translate}}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{'Principal Bal'|translate}}:</td>
                      <!-- <td>{{loanDetail?.original_principal | currency}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{loanDetail.current_principal | currency}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()" prefix="$"
                      mask="separator.2" 
                      thousandSeparator="," name="principal_balance" formControlName="principal_balance" class="form-control form-control-sm" value="" />
                      <val-errors controlName="principal_balance">
                        <ng-template valError="required">{{'Principal Balance is required'|translate}}.</ng-template>
                        <ng-template valError="currencyRange">{{'Principal Balance must be between 0 and 10000000'|translate}}.</ng-template>
                      </val-errors>
                    </td>
                  </tr>
                  <tr>
                    <td>{{'Interest Bal'|translate}}:</td>
                    <!-- <td>TBD</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{loanDetail.current_interest_balance | currency}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()" prefix="$"
                      mask="separator.2"
                      thousandSeparator="," name="interest_balance" formControlName="interest_balance" class="form-control form-control-sm" value="" />
                      <val-errors controlName="interest_balance">
                        <ng-template valError="required">{{'Interest Balance is required'|translate}}.</ng-template>
                        <ng-template valError="currencyRange">{{'Interest Balance must be between 0 and 100000'|translate}}.</ng-template>
                      </val-errors>
                    </td>
                  </tr>
                  <tr>
                    <td>{{'Late Fee'|translate}}:</td>
                    <!-- <td>TBD</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{loanDetail.account_late_fees_balance | currency}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()" prefix="$"
                      mask="separator.2"
                      thousandSeparator=","  name="late_fees" formControlName="late_fees"  class="form-control form-control-sm" value="" />
                      <val-errors controlName="late_fees">
                        <ng-template valError="required">{{'Late Fee is required'|translate}}.</ng-template>
                        <ng-template valError="currencyRange">{{'Late Fee must be between 0 and 100000'|translate}}.</ng-template>
                      </val-errors>
                    </td>
                  </tr>

                    <tr>
                      <td>{{'NSF Fee'|translate}}:</td>
                      <!-- <td>TBD</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{loanDetail.account_nsf_fees_balance | currency}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()" prefix="$"
                      mask="separator.2"
                      thousandSeparator="," name="nsf_fees" formControlName="nsf_fees"  class="form-control form-control-sm" value="" />
                      <val-errors controlName="nsf_fees">
                        <ng-template valError="required">{{'NSF Fee is required'|translate}}.</ng-template>
                        <ng-template valError="currencyRange">{{'NSF Fee must be between 0 and 100000'|translate}}.</ng-template>
                      </val-errors>
                    </td>
                  </tr>
                  <tr>
                    <td>{{'Other Fee'|translate}}:</td>
                    <!-- <td>TBD</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{loanDetail.account_other_fees_balance | currency}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()" prefix="$"
                      mask="separator.2"
                      thousandSeparator="," name="other_fees" formControlName="other_fees"  class="form-control form-control-sm" value="" />
                      <val-errors controlName="other_fees">
                        <ng-template valError="required">{{'Other Fee is required'|translate}}.</ng-template>
                        <ng-template valError="currencyRange">{{'Other Fee must be between 0 and 100000'|translate}}.</ng-template>
                      </val-errors>
                    </td>
                  </tr>
                  <tr>
                    <td>{{'APR'|translate}}:</td>
                    <!-- <td>{{loanDetail?.original_apr}}%</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{aprBeforeValue}}%
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()"  suffix="%" mask="separator.2" name="apr" formControlName="apr" class="form-control form-control-sm" value="" />
                      <val-errors controlName="apr">
                        <ng-template class="pl-2" valError="percentageRange"
                          >{{'APR must be more than 0 but not more than 500'|translate}}.</ng-template
                        >
                        <ng-template valError="required">{{'APR is required'|translate}}.</ng-template>
                      </val-errors>
                    </td>

                  </tr>

                  <tr>
                    <td>{{'Term Remaining'|translate}}:</td>
                    <!-- <td>{{loanDetail?.original_term}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{loanDetail.remaining_term}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()" suffix="" thousandSeparator="" mask="separator.0" name="term_remaining" formControlName="term_remaining" class="form-control form-control-sm" value="" />
                      <val-errors controlName="term_remaining">
                        <ng-template valError="currencyRange">{{'Term Remaining must be more than 0 but not more than 1000'|translate}}.</ng-template>
                        <ng-template valError="required">{{'Term Remaining is required'|translate}}.</ng-template>
                      </val-errors>
                    </td>
                  </tr>
                  <tr>
                    <td>{{'Frequency'|translate}}:</td>
                    <!-- <td>{{loanDetail?.original_frequency}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                      <td>
                        {{loanDetail.payment_frequency | titlecase }}
                      </td>
                      <td>
                        <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                        <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                      </td>
                      <td>
                        <select name="frequency" formControlName="frequency" class="form-control form-control-sm"> 
                          <option value="">{{'Select'|translate}}</option>
                          <option value="WEEKLY">{{'Weekly'|translate}}</option>
                          <option value="BIWEEKLY">{{'Biweekly'|translate}}</option>
                          <option value="MONTHLY">{{'Monthly'|translate}}</option>
                          <option value="SEMIMONTHLY">{{'Semimonthly'|translate}}</option>
                          <option value="ANNUAL">{{'Annual'|translate}}</option>
                          <option value="SEMIANNUAL">{{'Semiannual'|translate}}</option>
                        </select>
                        <val-errors controlName="frequency">
                          <ng-template valError="required">{{'Frequency is required'|translate}}</ng-template>
                        </val-errors>
                      </td>
                    </tr>
                    <tr>
                      <td>{{'Due Date'|translate}}:</td>
                      <!-- <td>{{ (loanDetail?.first_due_date | date: DATE_FORMAT_MMDDYYYY || '')}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{loanDetail.current_due_date | date: DATE_FORMAT_MMDDYYYY || ''}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <app-date-picker name="due_date" formControlName="due_date"></app-date-picker>
                      <val-errors controlName="due_date">
                        <ng-template valError="required">Due Date is required.</ng-template>
                        <ng-template valError="invalid_FFD_Before_DD"
                        >{{"Due Date cannot be before the First Due Date."}}{{' ('}}{{ loanDetail.first_due_date | date: DATE_FORMAT_MMDDYYYY || ''}}{{')'}}</ng-template
                        >
                      </val-errors>
                    </td>
                  </tr>
                  <tr>
                    <td>{{'Amount Due Plus Suspense'|translate}}:</td>
                    <!-- <td>{{ (loanDetail?.original_past_due | currency) || '$0.00'}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      {{ loanDetail.amount_due_plus_sus | currency}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()" prefix="$"
                      mask="separator.2" [allowNegativeNumbers]="true"
                      thousandSeparator="," name="amount_due" formControlName="amount_due" class="form-control form-control-sm" value="" />
                      <val-errors controlName="amount_due">
                        <ng-template valError="required">{{'Amount Due Plus Suspense is required'|translate}}.</ng-template>
                        <ng-template valError="currencyRange">{{'Amount Due Plus Suspense must be between -10000000 and 10000000'|translate}}.</ng-template>
                      </val-errors>
                    </td>
                  </tr>
                  <tr>
                    <td>{{'Payments'|translate}}:</td>
                    <!-- <td>{{loanDetail?.original_payment | currency}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                    </td> -->
                    <td>
                      <!-- {{ (this?.after_payment || 0) | currency}} -->
                      {{loanDetail.current_payment | currency}}
                    </td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td>
                      <input type="text" onfocus="select()" prefix="$"
                      mask="separator.2"
                      thousandSeparator="," #pay readonly name="payments" formControlName="payments" class="form-control form-control-sm" [value]="after_payment" />
                      <val-errors controlName="payments">
                        <ng-template valError="required">{{'Payments is required'|translate}}</ng-template>
                      </val-errors>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="cursor-pointer row mb-3 pr-2 pl-3" cdkDragHandle>
        <div class="col-md-6">
          <span class="d-block font-size-14 lms-text-light-pink" *ngIf="!isReamortizePermission">{{"You don’t have the permission to reamortize loans"|translate}}.</span>

          <div *ngIf="this.form.touched && !loanDetail?.any_active_side_note_exist && (after_payment=='0' || pay.value=='0')"><span class="d-block font-size-14 text-danger">{{"'Payments' cannot be $0.00"|translate}}.</span></div>
          <div *ngIf="loanDetail?.any_active_side_note_exist"><span class="d-block font-size-14 lms-text-light-pink">Loan modification is not allowed since there are side note(s) or CPI associated with this loan.</span></div>
          <!-- <val-errors controlName="payments">
          <ng-template valError="required"> <span class="d-block font-size-14 lms-text-light-pink">“Payments” cannot be $0.00</span></ng-template>
          <ng-template valError="min"> <span class="d-block font-size-14 lms-text-light-pink">“Payments” cannot be $0.00</span></ng-template>
        </val-errors> -->
        </div>
        <div class="col-md-6 text-right pr-4">
          <button type="button" class="btn  btn-150"
            [ngClass]="{'btn-success-light' : after_payment!='0','btn-secondary' : (after_payment<='0'|| after_payment<='0.00'|| pay.value<='0') }"
            [disabled]="after_payment<='0'|| after_payment<='0.00' || pay.value<='0' || loanDetail.any_active_side_note_exist"  (click)="onFormSubmit()">
            {{'Reamortize'|translate}}
          </button>
          <!-- <button type="button" class="btn btn-success-light btn-150" [disabled]="after_payment=='0'"  (click)="onFormSubmit()" *ngIf="isReamortizePermission && form.invalid==false">
          Reamortize
        </button> -->
        <!-- <button type="button" class="btn btn-secondary btn-150" [disabled]="form.invalid"  *ngIf="isReamortizePermission && form.invalid==true">
          Reamortize
        </button> -->

          <button *ngIf="!loanDetail?.any_active_side_note_exist && !isReamortizePermission" type="button" class="btn btn-pink btn-150" [disabled]="form.invalid" (click)="openGetApprovalModal(GetApprovalModal)">
            {{'Get Approval'|translate}}
          </button>
        
      </div>
    </div>
</form>


    <!-- Override Modal -->
    <ng-template #GetApprovalModal let-modal>
      <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
        <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
          <div class="modal-header " >
            <h6 class="modal-title text-center mb-3">{{'Get Approval'|translate}}</h6>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form #f="ngForm" [formGroup]="appform" (ngSubmit)="verifyPin()" novalidate>
            <div class="modal-body pb-0 px-0">


              <div class="lms-module-card">
                <div class="card-body">
                  <div class="card-form form-vertical">
                    <div class="form-group">
                      <label class="form-group-label">{{'Initials'|translate}}</label>
                      <input type="text" name="employee_initials" formControlName="employee_initials" class="form-control form-control-sm" value="EB1" />
                      <val-errors controlName="employee_initials">
                        <ng-template valError="required">{{'Initial is required'|translate}}</ng-template>
                      </val-errors>
                    </div>
                    <div class="form-group">
                      <label class="form-group-label">{{'PIN'|translate}}</label>
                      <input type="password" name="pin" formControlName="pin" class="form-control form-control-sm" value="212121" />
                      <val-errors controlName="pin">
                        <ng-template valError="required">{{'PIN is required'|translate}}</ng-template>
                      </val-errors>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" (click)="this.appform.valid ?  modal.dismiss('Cross click') : ''" class="btn btn-primary btn-block">
                {{'Get Approval'|translate}}
              </button>
            </div>
          </form>
        </div>
      </div>
</ng-template>
