

<!-- Client Environment Sticky Bar End-->
<div [ngClass]="{'show-login-as-client': (showClientBanner&&(!maintenanceStartWarning||!isMaintenancModeOn)) || (!showClientBanner&&(maintenanceStartWarning||isMaintenancModeOn)),
'show-login-as-client2':  showClientBanner&&(maintenanceStartWarning||isMaintenancModeOn)}">
 <div class="fixed-height-block">
  <div class="page-sticky-bar" *ngIf="showClientBanner||maintenanceStartWarning||isMaintenancModeOn">
    <div [ngClass]="{'pad': (showClientBanner&&(!maintenanceStartWarning||!isMaintenancModeOn)) || (!showClientBanner&&(maintenanceStartWarning||isMaintenancModeOn)),
    'pad2':  showClientBanner&&(maintenanceStartWarning||isMaintenancModeOn)}">
      <div>
        <ng-container *ngIf="showClientBanner">
          <strong>{{'Client Environment'|translate}}: {{ clientName }}</strong>
          <button
            type="button"
            class="btn btn-sm btn-logout btn-outline-light"
            (click)="logoutClient()"
          >
          {{'Logout'|translate}}
          </button>
        </ng-container>
      </div>
      <div>
        <ng-container *ngIf="maintenanceStartWarning">
          <strong>{{'Attention: Log off for nightly maintenance scheduled for 9:00pm Pacific Time'|translate}}</strong>
        </ng-container>
        <ng-container *ngIf="isMaintenancModeOn">
          <strong>{{'Attention: Application is in Maintenance Mode. Estimated completion time is 2:00am Pacific Time'|translate}}.</strong>
        </ng-container>
      </div>
    </div>
    
  </div>
  <div class="header-block">
    <!-- Client Environment Sticky Bar Start-->
    <!-- Login Header Top Start -->
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-sm">
          <div class="header">
            <div class="page-menubar light-theme">
              <a
                data-toggle="collapse"
                class="collapsed"
                aria-expanded="true"
                href="javascript:;"
                data-target="#sidebar"
                role="button"
              >
                <img
                  class="icon-light"
                  src="../assets/images/icons/hamburger-icon.png"
                />
                <img
                  class="icon-dark"
                  src="../assets/images/icons/hamburger-icon.png"
                />
              </a>
            </div>
            <a (click)="goToDashboard()"><img [src]="logo" height="38" /></a>
            <div [formGroup]="searchForm" *ngIf="searchPermission" class="header-search-wrapper">
              <div class="header-search">
                  <input type="text" id="search_key" formControlName="searchText" class="form-control search-control" placeholder="{{'Search'|translate}}" (keyup)="openSearchModal($event)" (onclick)="openSearchModal($event)" />
              </div>
              <div class="header-search-list" *ngIf="isSearchOpen">
                <ul *ngIf="searchData.length>0">
                  <li *ngFor="let sdata of searchData" (click)="goToRoute(sdata.id)">
                    <span style="font-size:12px;">{{sdata.loan_id}}{{' '}}{{sdata?.status}}{{' - '}}{{sdata?.label}}</span><br>
                    {{' '}}{{sdata?.firstname || ''}}{{' '}}{{sdata?.lastname || ''}}{{' '}}{{sdata?.vehicle_year}}{{' '}}{{sdata?.vehicle_make}}{{' '}}{{sdata?.vehicle_model}}{{' '}}{{sdata?.vehicle_vin}}</li>
                </ul>
                <ul *ngIf="searchData.length==0">
                    <li>{{'No Loan Found'|translate}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-auto">
          <div class="header-actions">
            <button title="Add New Loan" type="button" class="btn-icon phone" (click)="navigateTo()" *ngIf="canAccessLoanInformation">
              <span class="icon icon-cirlce">
                <img class="icon-light p-2" style="background-color: #ffffff;" src="../assets/images/header/add-loan-light.svg" alt="header icon"
                  aria-hidden="true" />
                <img class="icon-dark p-2" style="background-color: #404040;" src="../assets/images/header/add-loan-dark.svg" alt="header icon"
                  aria-hidden="true" />
              </span>
            </button>
            <button type="button" class="btn-icon message " (click)="openMessageModal(MessageModal)" *ngIf="SMSPermissionStatus!='NO'">
              <span class="icon icon-cirlce">
                <img class="icon-light" src="../assets/images/header/text-icon.png" alt="header icon"
                  aria-hidden="true" />
                <img class="icon-dark" src="../assets/images/header/text-icon-dark.png" alt="header icon"
                  aria-hidden="true" />
              </span>
              <div *ngIf="total_unread>0 && total_unread!==null " class="msg-notification ">
                {{total_unread}}
              </div>
            </button>
            <button type="button" class="btn-icon phone" (click)="openPhoneModal('')" *ngIf="CallPermissionStatus">
              <span class="icon icon-cirlce">
                <img class="icon-light" src="../assets/images/header/phone-icon.png" alt="header icon"
                  aria-hidden="true" />
                <img class="icon-dark" src="../assets/images/header/phone-icon-dark.png" alt="header icon"
                  aria-hidden="true" />
              </span>
            </button>
            <button type="button" class="btn-icon call" (click)="openCalculatorModal(CalculatorModal)">
              <span class="icon icon-cirlce">
                <img class="icon-light" src="../assets/images/header/calc-icon.png" alt="header icon"
                  aria-hidden="true" />
                <img class="icon-dark" src="../assets/images/header/calc-icon-dark.png" alt="header icon"
                  aria-hidden="true" />
              </span>
            </button>
            <div class="language-dropdown">
              <div class="dropdown">
                <button class="btn btn-icon dropdown-toggle" type="button" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" (click)="toggleDropdown()">
                  <img class="icon-custom" [src]="selectedLanguageIcon" alt="Selected Language Icon" aria-hidden="true" />
                  <span class="lang-text">{{ getSelectedLanguageValue() }}</span>
                </button>
                <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" [class.show]="dropdownOpen">
                  <a class="dropdown-item" *ngFor="let language of languages" 
                     (click)="changeLanguage(language)" 
                     [class.selected]="selectedLanguage === language.selectedLanguage"
                     [title]="language.languageValue"
                     href="javascript:void(0)">
                    <span>
                      <img class="icon-custom" src="{{language.icon}}" [alt]="language.languageValue">
                    </span>
                    {{language.languageValue}}
                  </a>
                </div> -->
              </div>
            </div>
              
            <div class="dropdown d-inline-block" *ngIf="!loginAsClient">
              <a href="javascript:void(0)" class="btn-icon user" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <span class="icon icon-cirlce">
                  <img width="40px" height="40px" [src]="this.prof_pic" alt="" aria-hidden="true" style="object-fit: cover;"/>
                </span>
              </a>
              <div class="dropdown-menu user-profile-dropdown dropdown-menu-right">
                <div class="d-flex justify-content-between align-items-center px-4">
                  <h6 class="mb-0 text-primary">{{"My Profile"|translate}}</h6>
                  <button type="button" class="btn btn-link text-secondary p-0">
                    <img width="9px" src="../assets/images/icons/dropdown-close.png" alt="" aria-hidden="true" />
                  </button>
                </div>
                <form action="#" method="POST">
                  <div class="text-center px-4 pb-4">
                    <span class="user-active-status online">{{"Online"|translate}}</span>
                    <div class="user-profile">
                      <img width="88px" height="88px" class="
                          rounded-circle
                          object-cover
                          border border-2 border-primary
                          mt-1
                          mb-2
                        " [src]="this.prof_pic" alt="" style="object-fit: cover;" />
                      <h6 class="mb-1 font-size-16">{{ userName }}</h6>
                      <p class="font-size-12">{{ userDesignation }}</p>
                    </div>
                    <div>
                      <a (click)="openChangePasswordModal(changePasswordModal)" href="javascript:void(0);"
                        class="btn-link font-size-12 text-underline">{{'Change Password'|translate}}</a>
                    </div>
                    <div>
                      <a href="javascript:void(0);" class="btn-link font-size-12 text-underline"
                        (click)="logout()">{{"Logout"|translate}}</a>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="px-4">
                    <h6 class="text-primary mb-1">{{"Settings"|translate}}</h6>
                    <div class="form-group row mb-0">
                      <label class="col-auto col-form-label">{{"Theme"|translate}}:</label>
                      <div class="col">
                        <select [(ngModel)]="userTheme" [ngModelOptions]="{ standalone: true }"
                          (change)="onThemeChange($event)" class="form-control form-control-sm w-auto">
                          <option *ngFor="let theme of themeOptions" [value]="theme.value"
                            [selected]="userTheme === theme.value">
                            {{ theme.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
                <!-- <div *ngIf="isChangePassword">
                  <app-update-password (onPasswordChanged)="onPasswordChanged($event)"></app-update-password>
                </div> -->
              </div>
            </div>
          </div>
          <ng-template #changePasswordModal let-modal>
            <div class="modal-header">
              <h6 class="modal-title" id="modal-basic-title">{{"Update Password"|translate}}</h6>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <app-update-password (onPasswordChanged)="onPasswordChanged($event)"></app-update-password>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- Login Header Top End -->

    <!-- Main Page Heading Start -->
    <!-- <div class="main-page-heading text-center">
      <div class="row">
        <div class="col-md-12">
          <h1 class="page-heading mb-0">{{sectionTitle}}</h1>
        </div>
      </div>
    </div> -->
    <!-- Main Page Heading End -->
  </div>
 </div>
</div>

<!-- Page Menubar Start -->
<!-- <div class="page-menubar">
  <a
    data-toggle="collapse"
    class="collapsed"
    aria-expanded="true"
    href="javascript:;"
    data-target="#sidebar"
    role="button"
  >
    <img
      class="icon-light"
      src="../assets/images/icons/hamburger-icon-dark.png"
    />
    <img class="icon-dark" src="../assets/images/icons/hamburger-icon.png" />
  </a>
</div> -->


<ng-template #CalculatorModal let-modal>
  <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content ">
  <div class="modal-header calculator-modal-header align-items-center  py-0  cursor-pointer" cdkDragHandle >
    <span class="icon icon-cirlce">
      <img class="icon-light" src="../assets/images/header/calc-icon.png" alt="" aria-hidden="true" width="44" />
      <img class="icon-dark" src="../assets/images/header/calc-icon-dark.png" alt="" aria-hidden="true" width="44" />
    </span>
    <h6 class="modal-title w-100 text-center" id="modal-basic-title">{{"Calculator"|translate}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="calculator">
      <div class="calculator number-overflow">
        <div class="calculator-screen" type="text">{{input}}</div>
      </div>
      <!-- <input type="text" class="calculator-screen" value="0" disabled /> -->
      <div class="calculator-screen"  type="text" value="0" disabled >{{result}}</div>


      <div class="calculator-keys">
        <button type="button" class="action" (click)="percentage()">%</button>
        <button type="button" class="action" (click)="clear()">CE</button>
        <button type="button" class="action" (click)="allClear()">C</button>
        <button type="button" class="action" (click)="delete()">{{"Delete"|translate}}</button>

        <button type="button" class="operator" (click)="reversesign()">+/-</button>
        <button type="button" class="operator" (click)="pressOperator('-')">-</button>
        <button type="button" class="operator" (click)="pressOperator('*')">X</button>
        <button type="button" class="operator" (click)="pressOperator('/')">/</button>
        <button type="button" class="operator plus-sign" (click)="pressOperator('+')">+</button>

        <button type="button" class="number" (click)="pressNum('7')">7</button>
        <button type="button" class="number" (click)="pressNum('8')">8</button>
        <button type="button" class="number" (click)="pressNum('9')">9</button>

        <button type="button" class="number" (click)="pressNum('4')">4</button>
        <button type="button" class="number" (click)="pressNum('5')">5</button>
        <button type="button" class="number" (click)="pressNum('6')">6</button>

        <button type="button" class="number" (click)="pressNum('1')">1</button>
        <button type="button" class="number" (click)="pressNum('2')">2</button>
        <button type="button" class="number" (click)="pressNum('3')">3</button>


        <button type="button" class="number zero-sign" (click)="pressNum('0')">0</button>
        <button type="button" class="number decimal" (click)="pressNum('.')">.</button>

        <button type="button" class="equal-sign" (click)="getAnswer()">=</button>

      </div>
    </div>
  </div>
  </div>
  </div>
</ng-template>

<!-- messageModal -->
<ng-template #MessageModal let-modal>
  <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <form [formGroup]="form">
      <div class="draggable-modal-content ">
        <div class="modal-header message-modal-header align-items-center  py-0 cursor-pointer" cdkDragHandle >
          <span class="icon icon-cirlce">
            <img class="icon-light" src="../assets/images/header/text-icon.png" alt="" aria-hidden="true" width="44" />
            <img class="icon-dark" src="../assets/images/header/text-icon-dark.png" alt="" aria-hidden="true"
              width="44" />
          </span>
          <div class="heading-text">
            <div class="text-alignement">
              <h6 class="modal-title text-center " [ngClass]="{'skyblue-color': getTotalUnRead() != ''}"
                id="modal-basic-title">
                <!-- {{isTemplateSelectionScreenActive==true?'Templates': showMessage==true?
                (showCountMessage == '' ? "MESSAGES" : showCountMessage):showMessageHeader }} -->
                {{isTemplateSelectionScreenActive==true?'Templates': showMessage==true?
                (getTotalUnRead() == '' ? ("MESSAGES"|translate) : getTotalUnRead()):showMessageHeader}}
              </h6>
            </div>
            <!-- <div class="text-alignement1">
              <h6 class="modal-title  text-center  darkred-color" id="modal-basic-title"
                *ngIf="showCountDeactivated != '' && showMessageChat==false">
                {{this.showCountDeactivated}}
              </h6>
            </div> -->
          </div>
          <button type="button" class="close" aria-label="Close" (click)="[refresh(),modal.dismiss('Cross click')]">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">

          <!-- Message notification Content -->
          <div class="message-notification-content" *ngIf="showMessage">
            <div class="message-notification-header">
              <!-- Search Start -->
                <div class="search">
                  <input type="text" id="messagesearch_key" class="form-control search-control" placeholder="{{'Search'|translate}}" formControlName="searchTextMessage" (keyup)="openmessageSearchModal($event)" (onclick)="openmessageSearchModal($event)" #customSearch />
                </div>
              <!-- <div class="search">
                <input type="text" class="form-control search-control" placeholder="Search" />
              </div> -->
              <!-- Search End -->
              <!-- Message Nav Start -->
              <nav class="nav lms-message-list-nav border-bottom">
                <button type="button" (click)="loadMessages(true)" class="btn nav-item"
                [ngClass]="{'active': loadAllMessages}">
                  <span>{{'All'|translate}}</span>
                </button>
                <button type="button" (click)="loadMessages(false)" class="btn nav-item"
                [ngClass]="{'active': !loadAllMessages}"
                *ngIf="this.isTemplateSelectionScreenActive!=true">
                  <span>{{"Unread"|translate}}</span>
                </button>
              </nav>
            </div>
            <div class="message-notification-body" (scroll)="onScroll()">
            <!-- {{thisAccountStatus}}  {{isAccountSelected}} {{this.isTemplateSelectionScreenActive!=true}} {{apiLoanDetailResponse?.applicant!=null}} {{insideLoanWorkScreen}} -->
              <div class="message-notification-divider"
                *ngIf="isAccountSelected && this.isTemplateSelectionScreenActive!=true && apiLoanDetailResponse?.applicant!=null &&insideLoanWorkScreen">
                {{"This Account"|translate}}
              </div>
              <div class="message-notification-list"
                *ngIf="isAccountSelected && this.isTemplateSelectionScreenActive!=true">
                <ng-container class="message-chat-content" *ngIf="(loadingMessages || !insideLoanWorkScreen) && current_screen_status!='OTHER'">
                  {{"Loading Messages"|translate}}{{"..."}}
                </ng-container>
                <ng-container class="message-chat-content" *ngIf="insideLoanWorkScreen">
                <ng-container *ngIf="(!thisAccountStatus && !this.loadingMessages) || ( apiLoanDetailResponse!=null && can_call_loan_detail == false && [null,undefined,''].includes(apiLoanDetailResponse?.applicant?.mobile_phone) && [null,undefined,''].includes(apiLoanDetailResponse?.co_applicant?.mobile_phone) && [null,undefined,''].includes(apiLoanDetailResponse?.co_signers[0]?.mobile_phone) && [null,undefined,''].includes(apiLoanDetailResponse?.co_signers[1]?.mobile_phone) && [null,undefined,''].includes(apiLoanDetailResponse?.company?.mobile_phone) && [null,undefined,''].includes(apiLoanDetailResponse?.recipient?.mobile_phone) )">
                  <div class="message-notification-item unread">
                    <div class="row mb-2">
                      <div class="col-12">
                        <div class="title">
                          {{"No Messages Found"|translate}}.
                        </div>
                      </div>
                    </div>

                  </div>

                </ng-container>



                <ng-container *ngIf="thisAccountStatus">
                  <ng-container *ngIf="apiLoanDetailResponse==null">
                    {{"Loading Messages for This Account"|translate}}...
                  </ng-container>
                  <ng-container *ngIf="apiLoanDetailResponse!=null">
                  <!-- Item 1  -->
                  <div class="message-notification-item unread"
                    *ngIf="apiLoanDetailResponse?.applicant?.mobile_phone!=null"
                    (click)="showChatBoxApplicant(apiLoanDetailResponse?.applicant?.opt_in_status,'APPLICANT',0)">
                    <div class="row mb-2">
                      <div class="col-6">
                        <div class="title">
                          {{ getFullName(apiLoanDetailResponse?.applicant) }}
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="title">
                          {{"Applicant"|translate}}
                        </div>
                      </div>


                      <div class="col-1 text-right"
                        *ngIf="apiLoanDetailResponse?.applicant?.opt_in_status!='DEACTIVATED'  && apiLoanDetailResponse?.applicant?.opt_in_status!='UNSUBSCRIBED'  &&   apiLoanDetailResponse?.applicant?.last_message?.applicants_unread_msgs > 0  && this.unreadApplicantIds?.includes(apiLoanDetailResponse?.applicant?.id)">
                        <!-- <em class="text-yellow">Unread</em> -->
                        <img src="assets/images/header/unread.png">
                      </div>
                      <div class="col-1 text-right" *ngIf="apiLoanDetailResponse?.applicant?.opt_in_status=='DEACTIVATED'">
                        <!-- <em class="text-yellow">Unread</em> -->
                        <img src="assets/images/header/dactive.png">
                      </div>
                    </div>


                    <div class="message text-truncate"
                      *ngIf="apiLoanDetailResponse?.applicant?.opt_in_status!=null && apiLoanDetailResponse?.applicant?.opt_in_status!='UNSUBSCRIBED' && apiLoanDetailResponse?.applicant?.opt_in_status!='DEACTIVATED'">
                      <ng-container *ngIf="loadAllMessages" >

                      {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(apiLoanDetailResponse?.applicant,loadAllMessages)}}

                      </ng-container>
                      <ng-container *ngIf="!loadAllMessages" >

                        {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(apiLoanDetailResponse?.applicant,loadAllMessages)}}

                      </ng-container>
                    </div>
                    <div class="message text-truncate"
                      *ngIf="apiLoanDetailResponse?.applicant?.opt_in_status==null || apiLoanDetailResponse?.applicant?.opt_in_status=='UNSUBSCRIBED'">
                      {{"Unregistered for SMS"|translate}}.
                    </div>
                    <div class="message text-truncate darkred-color"
                      *ngIf="apiLoanDetailResponse?.applicant?.opt_in_status=='DEACTIVATED'">
                      {{"DEACTIVATED"|translate}}:{{ formatPhoneNumber(apiLoanDetailResponse?.applicant?.mobile_phone)}}
                    </div>
                    <div class="date"
                      *ngIf="apiLoanDetailResponse?.applicant?.opt_in_status!=null && apiLoanDetailResponse?.applicant?.opt_in_status!='UNSUBSCRIBED' && !is_mode_change">
                      {{
                      convertLocalTimeZone(apiLoanDetailResponse?.applicant?.last_message?.created_at)
                      | date: DATE_TIME_FORMAT}}
                    </div>
                  </div>
                  <!-- Item 2 -->
                  <div class="message-notification-item unread"
                    *ngIf="apiLoanDetailResponse?.co_applicant?.mobile_phone!=null"
                    (click)="showChatBoxApplicant(apiLoanDetailResponse?.co_applicant?.opt_in_status,'CO-APPLICANT',0)">
                    <div class="row mb-2">
                      <div class="col-6">
                        <div class="title">

                          {{getFullName(apiLoanDetailResponse?.co_applicant)}}

                        </div>
                      </div>

                      <div class="col-5">
                        <div class="title">
                          {{"Co-Applicant"|translate}}
                        </div>
                      </div>
                      <div class="col-1 text-right"
                        *ngIf="apiLoanDetailResponse?.co_applicant?.opt_in_status!='DEACTIVATED' && apiLoanDetailResponse?.co_applicant?.opt_in_status!='UNSUBSCRIBED' &&  apiLoanDetailResponse?.co_applicant?.last_message?.applicants_unread_msgs > 0 && this.unreadApplicantIds?.includes(apiLoanDetailResponse?.co_applicant?.id)">
                        <!-- <em class="text-yellow">Unread</em> -->
                        <img src="assets/images/header/unread.png">
                      </div>
                      <div class="col-1 text-right" *ngIf="apiLoanDetailResponse?.co_applicant?.opt_in_status=='DEACTIVATED'">
                        <!-- <em class="text-yellow">Unread</em> -->
                        <img src="assets/images/header/dactive.png">
                      </div>
                    </div>
                    <div class="message text-truncate"
                      *ngIf="apiLoanDetailResponse?.co_applicant?.opt_in_status!=null && apiLoanDetailResponse?.co_applicant?.opt_in_status!='UNSUBSCRIBED' && apiLoanDetailResponse?.co_applicant?.opt_in_status!='DEACTIVATED'">
                      <ng-container *ngIf="loadAllMessages" >
                          {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(apiLoanDetailResponse?.co_applicant,loadAllMessages)}}
                      </ng-container>
                      <ng-container *ngIf="!loadAllMessages" >
                        {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(apiLoanDetailResponse?.co_applicant,loadAllMessages)}}
                      </ng-container>
                    </div>


                    <div class="message text-truncate"
                      *ngIf="apiLoanDetailResponse?.co_applicant?.opt_in_status==null || apiLoanDetailResponse?.co_applicant?.opt_in_status=='UNSUBSCRIBED'">
                      {{"Unregistered for SMS"|translate}}.
                    </div>
                    <div class="message text-truncate darkred-color"
                      *ngIf="apiLoanDetailResponse?.co_applicant?.opt_in_status=='DEACTIVATED'">
                      {{"DEACTIVATED"|translate}}: {{ formatPhoneNumber(apiLoanDetailResponse?.co_applicant?.mobile_phone) }}
                    </div>

                    <div class="date" *ngIf="apiLoanDetailResponse?.co_applicant?.opt_in_status!=null && apiLoanDetailResponse?.co_applicant?.opt_in_status!='UNSUBSCRIBED' && !is_mode_change">
                      {{
                      convertLocalTimeZone(apiLoanDetailResponse?.co_applicant?.last_message?.created_at)
                      | date: DATE_TIME_FORMAT}}

                    </div>
                  </div>
                  <!-- Item 3 co_signers 1 -->
                  <ng-container *ngFor="let item of apiLoanDetailResponse?.co_signers; index as i">
                    <div class="message-notification-item unread" *ngIf="item.mobile_phone!=null"
                      (click)="showChatBoxCoSigners(item?.opt_in_status,'co_signers',i,item?.applicant_type)">
                      <div class="row mb-2">
                        <div class="col-6">
                          <div class="title">
                            {{getFullName(item)|translate}}
                          </div>
                        </div>
                        <div class="col-5">
                          <div class="title">
                            {{((item?.applicant_type)) | translate }}
                          </div>
                        </div>
                        <div class="col-1 text-right" *ngIf="item?.opt_in_status!='DEACTIVATED' && item.opt_in_status!='UNSUBSCRIBED' &&  item?.last_message?.applicants_unread_msgs > 0  && this.unreadApplicantIds?.includes(item?.id)">
                          <!-- <em class="text-yellow">Unread</em> -->
                          <img src="assets/images/header/unread.png">
                        </div>
                        <div class="col-1 text-right" *ngIf="item?.opt_in_status=='DEACTIVATED'">
                          <!-- <em class="text-yellow">Unread</em> -->
                          <img src="assets/images/header/dactive.png">
                        </div>


                      </div>
                      <div *ngIf="item?.opt_in_status!=null && item?.opt_in_status!='DEACTIVATED' && item.opt_in_status!='UNSUBSCRIBED'" class="message text-truncate">
                        <ng-container *ngIf="loadAllMessages" >
                          {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(item,loadAllMessages)}}
                        </ng-container>
                        <ng-container *ngIf="!loadAllMessages" >
                          {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(item,loadAllMessages)}}
                        </ng-container>
                      </div>
                      <div class="message text-truncate"
                        *ngIf="item?.opt_in_status==null || item?.opt_in_status=='UNSUBSCRIBED'">
                        {{"Unregistered for SMS"|translate}}.
                      </div>
                      <div class="message text-truncate darkred-color" *ngIf="item?.opt_in_status=='DEACTIVATED'">
                        {{"DEACTIVATED"|translate}}: {{ formatPhoneNumber(item?.mobile_phone) }}
                      </div>
                      <div class="date" *ngIf="item?.opt_in_status!=null && item?.opt_in_status!='UNSUBSCRIBED' && !is_mode_change">
                        {{ convertLocalTimeZone(item?.last_message?.created_at)| date: DATE_TIME_FORMAT }}
                      </div>
                    </div>
                  </ng-container>
                  <!-- Item 5 Recipient  -->
                  <div class="message-notification-item unread"
                    *ngIf="apiLoanDetailResponse?.recipient!=null && apiLoanDetailResponse?.recipient?.mobile_phone!=null"
                    (click)="showChatBoxRec(apiLoanDetailResponse?.recipient?.opt_in_status,'RECIPIENT',0)">
                    <div class="row mb-2">
                      <div class="col-6">
                        <div class="title">
                          {{getFullName(apiLoanDetailResponse?.recipient)}}
                        </div>
                      </div>

                      <div class="col-5">
                        <div class="title">
                          {{"Recipient"|translate}}
                        </div>
                      </div>
                      <div class="col-1 text-right"
                        *ngIf="apiLoanDetailResponse?.recipient?.opt_in_status!='DEACTIVATED' && apiLoanDetailResponse?.recipient?.opt_in_status!='UNSUBSCRIBED' && apiLoanDetailResponse?.recipient?.last_message?.applicants_unread_msgs > 0 && this.unreadApplicantIds?.includes(apiLoanDetailResponse?.recipient?.id)">
                        <!-- <em class="text-yellow">Unread</em> -->
                        <img src="assets/images/header/unread.png">
                      </div>
                      <div class="col-1 text-right" *ngIf="apiLoanDetailResponse?.recipient?.opt_in_status=='DEACTIVATED'">
                        <!-- <em class="text-yellow">Unread</em> -->
                        <img src="assets/images/header/dactive.png">
                      </div>


                    </div>
                    <div *ngIf="apiLoanDetailResponse?.recipient?.opt_in_status!=null && apiLoanDetailResponse?.recipient?.opt_in_status!='DEACTIVATED' && apiLoanDetailResponse?.recipient?.opt_in_status!='UNSUBSCRIBED'"  class="message text-truncate" >
                      <ng-container *ngIf="loadAllMessages" >
                        {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(apiLoanDetailResponse?.recipient,loadAllMessages)}}
                        <!-- {{apiLoanDetailResponse?.recipient?.text_messages[apiLoanDetailResponse?.recipient?.text_messages?.length-1]?.message ?? 'No Messages'}} -->
                      </ng-container>
                      <ng-container *ngIf="!loadAllMessages" >
                        {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(apiLoanDetailResponse?.recipient,loadAllMessages)}}
                        <!-- {{apiLoanDetailResponse?.recipient?.unread_count!=0 ?(apiLoanDetailResponse?.recipient?.text_messages[apiLoanDetailResponse?.recipient?.text_messages?.length-1]?.message ):((apiLoanDetailResponse?.recipient?.unread_count??0) +' Unread Messages')}} -->
                      </ng-container>
                    </div>
                    <div class="message text-truncate"
                      *ngIf="apiLoanDetailResponse?.recipient?.opt_in_status==null || apiLoanDetailResponse?.recipient?.opt_in_status=='UNSUBSCRIBED'">
                      {{"Unregistered for SMS"|translate}}.
                    </div>
                    <div class="message text-truncate darkred-color"
                      *ngIf="apiLoanDetailResponse?.recipient?.opt_in_status=='DEACTIVATED'">
                      {{"DEACTIVATED"|translate}}: {{ formatPhoneNumber(apiLoanDetailResponse?.recipient?.mobile_phone) }}
                    </div>
                    <div class="date" *ngIf="apiLoanDetailResponse?.recipient?.opt_in_status!=null && apiLoanDetailResponse?.recipient?.opt_in_status!='UNSUBSCRIBED' && !is_mode_change">
                      {{ convertLocalTimeZone(apiLoanDetailResponse?.recipient?.last_message?.created_at) | date:
                        DATE_TIME_FORMAT}}

                    </div>
                  </div>
                  <!-- Item 6 company  -->
                  <div class="message-notification-item unread"
                    *ngIf="apiLoanDetailResponse?.company?.mobile_phone!=null"
                    (click)="showChatBoxCompany(apiLoanDetailResponse?.company?.opt_in_status,'COMPANY',0)">
                    <div class="row mb-2">
                      <div class="col-6">
                        <div class="title">
                          {{ apiLoanDetailResponse?.company?.company_name ?? '' }}
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="title">
                          {{"Company"|translate}}
                        </div>
                      </div>


                      <div class="col-1 text-right"
                        *ngIf="apiLoanDetailResponse?.company?.opt_in_status!='DEACTIVATED'  && apiLoanDetailResponse?.company?.opt_in_status!='UNSUBSCRIBED'  &&   apiLoanDetailResponse?.company?.last_message?.applicants_unread_msgs > 0  && this.unreadCompanyIds?.includes(apiLoanDetailResponse?.company?.id?.toString())">
                        <!-- <em class="text-yellow">Unread</em> -->
                        <img src="assets/images/header/unread.png">
                      </div>
                      <div class="col-1 text-right" *ngIf="apiLoanDetailResponse?.company?.opt_in_status=='DEACTIVATED'">
                        <!-- <em class="text-yellow">Unread</em> -->
                        <img src="assets/images/header/dactive.png">
                      </div>
                    </div>


                    <div class="message text-truncate"
                      *ngIf="apiLoanDetailResponse?.company?.opt_in_status!=null && apiLoanDetailResponse?.company?.opt_in_status!='UNSUBSCRIBED' && apiLoanDetailResponse?.company?.opt_in_status!='DEACTIVATED'">
                      <ng-container *ngIf="loadAllMessages" >

                      {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(apiLoanDetailResponse?.company,loadAllMessages)}}

                      </ng-container>
                      <ng-container *ngIf="!loadAllMessages" >

                        {{ is_mode_change ? ("Loading..."|translate) : getLastMessage(apiLoanDetailResponse?.company,loadAllMessages)}}

                      </ng-container>
                    </div>
                    <div class="message text-truncate"
                      *ngIf="apiLoanDetailResponse?.company?.opt_in_status==null || apiLoanDetailResponse?.company?.opt_in_status=='UNSUBSCRIBED'">
                      {{"Unregistered for SMS"|translate}}.
                    </div>
                    <div class="message text-truncate darkred-color"
                      *ngIf="apiLoanDetailResponse?.company?.opt_in_status=='DEACTIVATED'">
                      {{"DEACTIVATED"|translate}}:{{ formatPhoneNumber(apiLoanDetailResponse?.company?.mobile_phone)}}
                    </div>
                    <div class="date"
                      *ngIf="apiLoanDetailResponse?.company?.opt_in_status!=null && apiLoanDetailResponse?.company?.opt_in_status!='UNSUBSCRIBED' && !is_mode_change">
                      {{
                      convertLocalTimeZone(apiLoanDetailResponse?.company?.last_message?.created_at)
                      | date: DATE_TIME_FORMAT}}
                    </div>
                  </div>
                </ng-container>
                </ng-container>
                </ng-container>



                <!-- Item 6 Refrences  -->
                <!-- <div  *ngIf="apiLoanDetailResponse?.references?.length>0">

            <div *ngFor="let item of apiLoanDetailResponse?.references; let i=index;">
            <div class="message-notification-item unread" *ngIf="item?.full_name!=null"   (click)="showChatBoxRef(item?.opt_in_status,'references',i)">
            <div class="row mb-2">
              <div class="col-6">
                <div class="title">
                  {{item?.full_name}}

                </div>
              </div>

              <div class="col-4">
                <div class="title">
                  References {{ i+1 }}
                  </div>
              </div>
              <div class="col-auto" *ngIf="item?.text_messages?.read==0"> -->
                <!-- <em class="text-yellow">Unread</em> -->
                <!-- <img src="assets/images/header/unread.png">
              </div>
            </div>
            <div class="message text-truncate">
              {{item?.text_messages?.message}}
            </div>
            <div class="message text-truncate" *ngIf="item?.opt_in_status==null || item?.opt_in_status=='UNSUBSCRIBED'">
              {{"Unregistered for SMS"|translate}}.
            </div>
            <div class="date">
              {{  convertLocalTimeZone(item?.text_messages?.created_at) | date: TIME_FORMAT}}
            </div>
          </div>
        </div>
        </div> -->


              </div>

              <div class="message-notification-divider" *ngIf="this.isTemplateSelectionScreenActive!=true">
                {{"All Accounts"|translate}}
              </div>

              <!--------show message list---------->
              <div class="message-notification-list" *ngIf="this.isTemplateSelectionScreenActive!=true">


                <!-- <ng-container
                  *ngIf="((!this.allAccountsStatus || (apiTextMesasages?.length==1 && apiTextMesasages[0]?.loan_id==selectedLoanId)) && !this.current_mode) || (ALLSMSPermission=='NO' && !is_In_queue)"> -->

                  <!-- <ng-container
                  *ngIf="((apiTextMesasages?.length==0 && !this.is_loanTextMessagesInProcess) || ((!this.allAccountsStatus || (apiTextMesasages?.length==1 && apiTextMesasages[0]?.loan_id==selectedLoanId)) && !this.current_mode) || (ALLSMSPermission=='NO' && !is_In_queue)) && !this.is_loanTextMessagesInProcess">

                  <div class="message-notification-item unread">
                    <div class="row mb-2">
                      <div class="col-12">
                        <div class="title">
                          No Messages Found.
                        </div>
                      </div>
                    </div>

                  </div>

                </ng-container> -->

                <!-- Item 1  -->

                <!-- <ng-container *ngIf="this.allAccountsStatus"> -->

                  <div *ngIf="this.is_loanTextMessagesInProcess && apiTextMesasages?.length==0">
                    {{"Loading Messages"|translate}}{{"..."}}
                    </div>
                  <div *ngFor="let item of getFilterTextMessages(); let i=index;">
                    <ng-container *ngIf="loadAllMessages" >
                      <div class="message-notification-item" *ngIf="item?.loan_id!=selectedLoanId"
                      (click)="selectLoan(item,item?.opt_in_status,item?.loan_id,item?.user_type)">
                        <div class="row mb-2">
                          <div class="col">
                            <div  *ngIf="item?.user_type != 'COMPANY'" class="title">
                              {{ item.applicant }}
                            </div>
                            <div *ngIf="item?.user_type == 'COMPANY'" class="title">
                              {{ item.company + ' ' + '(' +item.loan_loan_id + ')' }}
                            </div>
                          </div>
                          <div class="col-auto" *ngIf="item.read==0 && item.opt_in_status!='DEACTIVATED' && item.opt_in_status!='UNSUBSCRIBED'">
                            <!-- <em class="text-yellow">Unread</em> -->
                            <img src="assets/images/header/unread.png">
                          </div>

                          <div class="col-auto" *ngIf="item.opt_in_status=='DEACTIVATED'">
                            <!-- <em class="text-yellow">Unread</em> -->
                            <img src="assets/images/header/dactive.png">
                          </div>
                        </div>
                        <div class="message text-truncate" *ngIf="item?.opt_in_status!='DEACTIVATED'  && item.opt_in_status!='UNSUBSCRIBED'">
                          {{ item?.title!='SBTMMS'? item?.message : item?.s3_url?.substr(item?.s3_url?.indexOf("/")+1) }}
                        </div>
                        <div class="message text-truncate darkred-color" *ngIf="item?.opt_in_status=='DEACTIVATED' ">
                          {{"DEACTIVATED"|translate}}:{{ formatPhoneNumber(item?.mobile_phone)}}
                        </div>
                        <div class="message text-truncate yellow-color" *ngIf="item?.opt_in_status=='UNSUBSCRIBED' ">
                          {{"Unregistered for SMS"|translate}} :{{ formatPhoneNumber(item?.mobile_phone)}}
                        </div>
                        <div class="date" *ngIf="item?.opt_in_status!='DEACTIVATED'  && item.opt_in_status!='UNSUBSCRIBED'" >
                          {{ convertLocalTimeZone(item?.created_at) | date: DATE_TIME_FORMAT}}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!loadAllMessages">
                      <div class="message-notification-item" *ngIf="item?.loan_id!=selectedLoanId && item?.read==0"
                      (click)="selectLoan(item,item?.opt_in_status,item?.loan_id,item?.user_type)">
                        <div class="row mb-2">
                          <div class="col">
                            <div  *ngIf="item?.user_type != 'COMPANY'" class="title">
                              {{ item.applicant }}
                            </div>
                            <div *ngIf="item?.user_type == 'COMPANY'" class="title">
                              {{ item.company + ' ' + '(' +item.loan_loan_id + ')' }}
                            </div>
                          </div>
                          <div class="col-auto" *ngIf="item.read==0 && item.opt_in_status!='DEACTIVATED' && item.opt_in_status!='UNSUBSCRIBED'">
                            <!-- <em class="text-yellow">Unread</em> -->
                            <img src="assets/images/header/unread.png">
                          </div>
                          <div class="col-auto" *ngIf="item.opt_in_status=='DEACTIVATED'">
                            <!-- <em class="text-yellow">Unread</em> -->
                            <img src="assets/images/header/dactive.png">
                          </div>
                        </div>
                        <div class="message text-truncate" *ngIf="item?.opt_in_status!='DEACTIVATED' && item.opt_in_status!='UNSUBSCRIBED'">
                          {{ item?.title!='SBTMMS'? item?.message : item?.s3_url?.substr(item?.s3_url?.indexOf("/")+1) }}
                        </div>
                        <div class="message text-truncate darkred-color" *ngIf="item?.opt_in_status=='DEACTIVATED'">
                          {{"DEACTIVATED"|translate}}:{{ formatPhoneNumber(item?.mobile_phone)}}
                        </div>
                        <div class="message text-truncate yellow-color" *ngIf="item?.opt_in_status=='UNSUBSCRIBED' ">
                          {{"Unregistered for SMS"|translate}}. :{{ formatPhoneNumber(item?.mobile_phone)}}
                        </div>
                        <div class="date" *ngIf="item?.opt_in_status!='DEACTIVATED'  && item.opt_in_status!='UNSUBSCRIBED'">
                          {{ convertLocalTimeZone(item?.created_at) | date: DATE_TIME_FORMAT}}
                        </div>
                      </div>
                      <!-- <div *ngIf="item?.loan_id!=selectedLoanId &&unreadMessagesCount>0">
                        <div class="message-notification-item unread">
                          <div class="row mb-2">
                            <div class="col-12">
                              <div class="title">

                                {{'0 unread messages in other accounts.'}}
                              </div>
                            </div>
                          </div>

                        </div> 
                      </div> -->
                    </ng-container>
                  </div>
                  <ng-container *ngIf="!is_In_queue && ALLSMSPermission=='YES'">
                  <div *ngIf="this.isLoading  && this.apiTextMesasages.length > 0">
                    {{"Loading Messages"|translate}} {{"..."}}
                  </div>
                  </ng-container>
                  <ng-container *ngIf="is_In_queue">
                    <div *ngIf="this.isLoading  && this.apiTextMesasages.length > 0">
                      {{"Loading Messages"|translate}} {{"..."}}
                    </div>
                    </ng-container>
                  <!-- <div *ngIf="(totalUnreadMessagesCount-selectedLoanUnreadMessageCount)==0 && !loadAllMessages && apiTextMesasages"> -->
                    <div *ngIf="(this.total_unread == 0 && !loadAllMessages && apiTextMesasages && ALLSMSPermission=='YES' && !this.is_loanTextMessagesInProcess) || (this.total_unread != 0 && !this.is_loanTextMessagesInProcess && !current_mode && !otherUnreadMessagesPresent(selectedLoanId) && ALLSMSPermission=='YES') || ( this.messageSearchKey.length>=3 && apiTextMesasages.length==0 && !this.is_loanTextMessagesInProcess  ) || (ALLSMSPermission=='NO' && !this.is_loanTextMessagesInProcess && apiTextMesasages.length==0 && is_In_queue) || (ALLSMSPermission=='NO' && !this.is_loanTextMessagesInProcess && apiTextMesasages.length>=0 && !is_In_queue) || (getFilterTextMessages().length==0 && apiTextMesasages.length!=0 && !this.is_loanTextMessagesInProcess && current_mode) ||(apiTextMesasages.length==0 && !this.is_loanTextMessagesInProcess)">
                    <div class="message-notification-item unread">
                      <div class="row mb-2">
                        <div class="col-12">
                          <div class="title">
                            {{'No Messages Found'|translate}}.
                            <!-- {{'0 Unread Messages'}} -->
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                  <!-- <div *ngIf="getFilterTextMessages().length==0 && apiTextMesasages.length!=0 && !this.is_loanTextMessagesInProcess && current_mode">
                    <div class="message-notification-item unread">
                      <div class="row mb-2">
                        <div class="col-12">
                          <div class="title">
                            No Messages Found.

                          </div>
                        </div>
                      </div>

                    </div>

                  </div> -->
                <!-- </ng-container> -->





              </div>
              <!--------show message list---------->
              <!-- <div class="message-notification-list"  *ngIf="this.isTemplateSelectionScreenActive==true">





    <div  *ngFor="let item of apiTemplates; let i=index;">
    <div class="message-notification-item">



      <div class="message text-truncate">
        {{ item.Text }}
      </div>




    </div>
  </div>



  </div> -->
              <!--------end show message list---------->
            </div>
          </div>

          <!-- Message Chat Content -->
          <div class="message-chat-content" *ngIf="showMessageChat">
            <div class="message-chats">
              <ul id="chat" infinite-scroll [scrollWindow]="false" [alwaysCallback]="true" [infiniteScrollThrottle]="0"
              [infiniteScrollUpDistance]="scrollUpDistance" (scrolledUp)="onInfiniteScroll()" *ngIf="current_user_type=='APPLICANT'">

                <li  class="you" [ngClass]="{'me': item?.message_type== 'OUTBOUND' ,'you': item?.message_type== 'INBOUND'}"
                  *ngFor="let item of chatMessageList; let i=index;">
                  <!-- <div class="time" *ngIf="item?.message_type== 'OUTBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->
                  <div class="time" > {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div>
                  <div *ngIf="item?.s3_url!=''" class="message template-word-break">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                    <a [href]="imageUrl + item?.s3_url" target="_blank">
                      <img [src]="imageUrl + item?.s3_url" width="100" height="150" />
                    </a>
                    <br>
                    <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                    <!-- {{imageUrl+item.s3_url}} -->
                  </div>
                  <div *ngIf="item?.s3_url==''"  class="message template-word-break">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                        <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                  </div>
                  <!-- <div class="time" *ngIf="item?.message_type== 'INBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->

                </li>

              </ul>

              <ul id="chat" infinite-scroll [scrollWindow]="false" [alwaysCallback]="true" [infiniteScrollThrottle]="0"
              [infiniteScrollUpDistance]="scrollUpDistance" (scrolledUp)="onInfiniteScroll()" *ngIf="current_user_type=='CO-APPLICANT'">

                <li class="you" [ngClass]="{'me': item?.message_type== 'OUTBOUND' ,'you': item?.message_type== 'INBOUND'}"
                  *ngFor="let item of chatMessageList; let i=index;">
                  <!-- <div class="time" *ngIf="item?.message_type== 'OUTBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->
                  <div class="time" > {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div>
                  <div *ngIf="item?.s3_url!=''" class="message template-word-break">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                    <a [href]="imageUrl + item?.s3_url" target="_blank">
                      <img [src]="imageUrl + item?.s3_url" width="100" height="150"/>
                    </a>
                    <br>
                    <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                    <!-- {{imageUrl+item.s3_url}} -->
                  </div>
                  <div *ngIf="item?.s3_url==''" class="message template-word-break">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                    <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                  </div>
                  <!-- <div class="time" *ngIf="item?.message_type== 'INBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->

                </li>

              </ul>

              <ul id="chat" infinite-scroll [scrollWindow]="false" [alwaysCallback]="true" [infiniteScrollThrottle]="0"
              [infiniteScrollUpDistance]="scrollUpDistance" (scrolledUp)="onInfiniteScroll()" *ngIf="current_user_type=='CO-SIGNER-1'||current_user_type=='CO-SIGNER-2'">

                <li class="you" [ngClass]="{'me': item?.message_type== 'OUTBOUND' ,'you': item?.message_type== 'INBOUND'}"
                  *ngFor="let item of chatMessageList; let i=index;">
                  <!-- <div class="time" *ngIf="item?.message_type== 'OUTBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->
                  <div class="time" > {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div>
                  <div *ngIf="item?.s3_url!=''" class="message template-word-break">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                    <a [href]="imageUrl + item?.s3_url" target="_blank">
                      <img [src]="imageUrl + item?.s3_url" width="100" height="150"/>
                    </a>
                    <br>
                    <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                    <!-- {{imageUrl+item.s3_url}} -->
                  </div>
                  <div *ngIf="item?.s3_url==''" class="message template-word-break">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                    <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                  </div>
                  <!-- <div class="time" *ngIf="item?.message_type== 'INBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->

                </li>

              </ul>

              <ul id="chat" infinite-scroll [scrollWindow]="false" [alwaysCallback]="true" [infiniteScrollThrottle]="0"
              [infiniteScrollUpDistance]="scrollUpDistance" (scrolledUp)="onInfiniteScroll()" *ngIf="current_user_type=='RECIPIENT'">

                <li class="you" [ngClass]="{'me': item?.message_type== 'OUTBOUND' ,'you': item?.message_type== 'INBOUND'}"
                  *ngFor="let item of chatMessageList; let i=index;">
                  <!-- <div class="time" *ngIf="item?.message_type== 'OUTBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->
                  <div class="time" > {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div>
                  <div *ngIf="item?.s3_url!=''"  class="message template-word-break">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                    <a [href]="imageUrl + item?.s3_url" target="_blank">
                      <img [src]="imageUrl + item?.s3_url" width="100" height="150"/>
                    </a>
                    <br>
                    <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                    <!-- {{imageUrl+item.s3_url}} -->
                  </div>
                  <div *ngIf="item?.s3_url==''"  class="message template-word-break">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span>
                    <br>
                    <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                  </div>
                  <!-- <div class="time" *ngIf="item?.message_type== 'INBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->

                </li>

              </ul>

              <ul id="chat" infinite-scroll [scrollWindow]="false" [alwaysCallback]="true" [infiniteScrollThrottle]="0"
              [infiniteScrollUpDistance]="scrollUpDistance" (scrolledUp)="onInfiniteScroll()" *ngIf="current_user_type=='COMPANY'">

                <li class="you" [ngClass]="{'me': item?.message_type== 'OUTBOUND' ,'you': item?.message_type== 'INBOUND'}"
                  *ngFor="let item of chatMessageList; let i=index;">
                  <!-- <div class="time" *ngIf="item?.message_type== 'OUTBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->
                  <div class="time" > {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div>
                  <div *ngIf="item?.s3_url!=''" class="message">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                    <a [href]="imageUrl + item?.s3_url" target="_blank">
                      <img [src]="imageUrl + item?.s3_url" width="100" height="150" />
                    </a>
                    <br>
                    <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                    <!-- {{imageUrl+item.s3_url}} -->
                  </div>
                  <div *ngIf="item?.s3_url==''"  class="message">
                    <span [ngClass]="{'mob_me': item?.message_type== 'OUTBOUND' ,'mob_you': item?.message_type== 'INBOUND'}">{{ convertToUSFormat(item?.mobile_phone ) }}</span><br>
                        <div  [innerHTML]="getSanitizedSentence(item?.message,item?.message_type)"></div>
                  </div>
                  <!-- <div class="time" *ngIf="item?.message_type== 'INBOUND'"> {{ convertLocalTimeZone(item?.created_at) |
                    date: DATE_TIME_FORMAT}}
                  </div> -->

                </li>

              </ul>

              <!-- <ul id="chat" *ngIf="current_user_type=='co_applicant'">

                <li class="you" [ngClass]="{'me': item?.message_type== 'OUTBOUND' ,'you': item?.message_type== 'INBOUND'}"
                  *ngFor="let item of apiLoanDetailResponse?.co_applicant?.text_messages; let i=index;">
                  <div class="time"> {{ convertLocalTimeZone(item?.created_at) | date: DATE_TIME_FORMAT}}
                  </div>
                  <div class="message template-word-break">
                    {{item?.message}}
                  </div>

                </li>

              </ul> -->



            </div>
            <div class="message-chat-form">
              <div *ngIf="!dnc" class="row align-items-center justify-content-between">
                <div class="col-auto">
                  <button type="button" class="btn btn-link p-0 text-underline"
                    (click)="showTemplates()">{{"Template"|translate}}</button>
                </div>
                <div class="col-auto">
                  <span class="font-size-12">{{freeMessage.length}}/500</span>
                </div>
                <span *ngIf="sms_service=='TMP'" class="font-size-12">&nbsp;&nbsp;&nbsp;{{"Note: Text longer than 149 characters will be sent out as multiple messages."|translate}}</span>
              </div>
              <div *ngIf="dnc" class="mb-2">
                <span class="font-size-12 yellow-color">{{"Outgoing messages have been ceased. To send messages, disable DNC in Loan Information"|translate}}.</span>
              </div>
              
                <div *ngIf="!dnc" class="form-group">
                  <textarea [maxlength]="500" rows="3" formControlName="message" name="message" class="form-control border-0"
                    [readOnly]="isTemplateMessageSend || SMSPermissionStatus=='TEMPLATES_ONLY'"></textarea>
                </div>
                <div class="row cursor-pointer" cdkDragHandle>
                  <div class="col">
                    <button type="button" class="btn btn-pink btn-block" (click)="goBack('showMessage')">{{"Back"|translate}}</button>
                  </div>
                  <div class="col">
                    <!-- <button type="button" class="btn btn-success-light btn-block" (click)="goBackFromToAccount('showMessage')">To Account</button> -->
                    <button type="button" class="btn btn-success-light btn-block" (click)="goToAccount()">
                      {{'To Account'|translate}}</button>
                  </div>
                  <div class="col">
                    <button type="button" class="btn btn-primary btn-block" [disabled]="isMessageBoxFilled || freeMessage.length>500 || dnc"
                      (click)="sendTemplateMessage()">{{"Send"|translate}}</button>
                  </div>
                </div>
              
            </div>
          </div>
          <!-- Template List Content -->

          <!-- Message Notification Content -->
          <div class="message-notification-content" *ngIf="this.isTemplateSelectionScreenActive==true">
            <!-- <div class="message-notification-header"> -->
            <!-- Search Start -->
            <!-- <div class="search">
            <input type="text" class="form-control search-control" placeholder="Search" />
          </div> -->
            <!-- Search End -->
            <!-- Message Nav Start -->
            <!-- <nav class="nav lms-message-list-nav border-bottom">
            <button type="button" class="btn nav-item active">
              <span>All</span>
            </button>
          </nav> -->
            <!-- </div> -->
            <div class="message-notification-body">
              <div class="message-notification-list mt-1">
                <!-- Item 1  -->
                <div class="message-notification-item cursor-pointer-template" [ngClass]="{'selectedTemplate': i==this.selected_template_id}"
                  *ngFor="let item of apiTemplates; let i=index;" (click)="selectTemplates(i,item?.TemplateID)">
                  <div class="message template-word-break">
                    {{ item.Text }}
                  </div>
                </div>




              </div>
            </div>
            <div class="message-footer-content cursor-pointer" cdkDragHandle>
              <div class="row">
                <div class="col">
                  <button type="button" class="btn btn-pink btn-block" (click)="goBack('Message_Chat')">{{"Back"|translate}}</button>
                </div>
                <div class="col">
                  <!-- <button type="button" class="btn btn-success-light btn-block" (click)="goBackFromToAccount('showMessage')">To Account</button> -->
                  <button type="button" class="btn btn-success-light btn-block" (click)="goToAccount()">
                    {{'To Account'|translate}}</button>
                </div>
                <div class="col">
                  <button type="button" class="btn btn-primary btn-block" (click)="sendTemplate()">{{"Select"|translate}}</button>
                </div>
              </div>
            </div>
          </div>

          <!-- Message Notification Content -->
          <div class="message-notification-content" *ngIf="showMessageOptin">
            <div class="message-notification-body">
              <div class="message-optin-content p-4 text-center" *ngIf="showMessageOptinSend">
                <h6 class="mb-3">{{"OPT-IN FOR SMS"|translate}}</h6>
                <div *ngIf="dnc" class="font-size-12 yellow-color">{{'Outgoing messages have been ceased. To send Opt In, disable DNC in Loan Information'|translate}}.</div>
                <div *ngIf="!dnc" class="font-size-12">{{"Customer isn't registered for SMS alerts. Before opting them in, ensure you have their permission. Click the 'Opt In' button, and they'll automatically receive a confirmation text"|translate}}.</div>
              </div>
              <div class="message-optin-content p-4 text-center" *ngIf="showMessageOptinVerify && auto_subscribe">
                <h6 class="mb-3">{{"OPT-IN FOR SMS"|translate}}</h6>
                <div class="font-size-12">{{"Auto subscribing. Please wait"|translate}}.</div>
              </div>
              <div class="message-optin-content p-4 text-center" *ngIf="showMessageOptinVerify && !auto_subscribe">
                <h6 class="mb-3">{{"OPT-IN FOR SMS"|translate}}</h6>
                <div class="font-size-12">{{"Please enter the "|translate}}{{vendorConfig?.pin_size ?? 6}} {{" digit code sent to customer over SMS"|translate}}</div>
                <div class="card-form-content card-form-success">
                  <div 
                  [ngClass]="{'otp-controls': vendorConfig.pin_size=='6' ,'otp-controls-5': vendorConfig.pin_size=='5','otp-controls-4': vendorConfig.pin_size=='4'}"
                  >
                    <!-- <div class="otp-controls"> -->
                    <ng-container *ngIf="vendorConfig.pin_size=='4'">
                      <input type="text" class="form-control text-center" #pin1
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,'','',$event,'',pin1,pin2)"
                        maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input type="text" class="form-control text-center" #pin2 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,'','',$event,pin1,pin2,pin3)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input type="text" class="form-control text-center" #pin3 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,'','',$event,pin2,pin3,pin4)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input  type="text" class="form-control text-center" #pin4 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,'','',$event,pin3,pin4,'')"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                    </ng-container>
                    <ng-container *ngIf="vendorConfig.pin_size=='5'">
                      <input type="text" class="form-control text-center" #pin1
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,'',$event,'',pin1,pin2)"
                        maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input type="text" class="form-control text-center" #pin2 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,'',$event,pin1,pin2,pin3)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input type="text" class="form-control text-center" #pin3 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,'',$event,pin2,pin3,pin4)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input  type="text" class="form-control text-center" #pin4 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,'',$event,pin3,pin4,pin5)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input  type="text" class="form-control text-center" #pin5 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,'',$event,pin4,pin5,'')"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                    </ng-container>
                    <ng-container *ngIf="vendorConfig.pin_size=='6'">
                      <input type="text" class="form-control text-center" #pin1
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,pin6.value,$event,'',pin1,pin2)"
                        maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input type="text" class="form-control text-center" #pin2 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,pin6.value,$event,pin1,pin2,pin3)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input type="text" class="form-control text-center" #pin3 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,pin6.value,$event,pin2,pin3,pin4)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input  type="text" class="form-control text-center" #pin4 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,pin6.value,$event,pin3,pin4,pin5)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input  type="text" class="form-control text-center" #pin5 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,pin6.value,$event,pin4,pin5,pin6)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      <input  type="text" class="form-control text-center" #pin6 maxlength="1"
                        (keyup)="getPin(pin1.value,pin2.value,pin3.value,pin4.value,pin5.value,pin6.value,$event,pin5,pin6,'')"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                    </ng-container>

                  </div>
                </div>
              </div>
            </div>
            <div class="message-footer-content cursor-pointer" cdkDragHandle>


              <!--------------Send Optin------------->
              <div class="row" *ngIf="showMessageOptinSend">
                <div class="col">
                  <button type="button" class="btn btn-pink btn-block" (click)="goBack('showMessage')">{{"Back"|translate}}</button>
                </div>
                <div class="col">
                  <!-- <button type="button" class="btn btn-success-light btn-block" (click)="goBackFromToAccount('showMessage')">To Account</button> -->
                  <button type="button" [disabled]="this.current_saved_applicant?.type=='standard_settings'"  class="btn btn-success-light btn-block" (click)="goToAccount()">
                    {{'To Account'|translate}}</button>
                </div>
                <div class="col">
                  <button [disabled]="dnc" type="button" (click)="sendOptin(selected_applicant_id)"
                    class="btn btn-primary btn-block">{{"Opt In"|translate}}</button>
                </div>
              </div>
              <!--------------Verify Optin------------->
              <div class="row" *ngIf="showMessageOptinVerify">
                <div class="col">
                  <button type="button" (click)="goBack('showMessage')" class="btn btn-pink btn-block">{{"Back"|translate}}</button>
                </div>
                <div class="col">
                  <!-- <button type="button" class="btn btn-success-light btn-block" (click)="goBackFromToAccount('showMessage')">To Account</button> -->
                  <button type="button" [disabled]="this.current_saved_applicant?.type=='standard_settings'"  class="btn btn-success-light btn-block" (click)="goToAccount()">
                    {{'To Account'|translate}}</button>

                </div>
                <div class="col">
                  <button type="button" (click)="verifyOptinCode(selected_applicant_id)" [disabled]="isPinNotValid"
                    class="btn btn-primary btn-block">{{'Confirm'|translate}}</button>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="message-footer-content cursor-pointer" cdkDragHandle></div>
      </div>
    </form>
  </div>
</ng-template>