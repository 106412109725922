import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueDetailServiceService {
  constructor() { }
  private loadDetailResponse = new Subject();
  currentloanDeatilResponse = this.loadDetailResponse.asObservable();

  private standardSettingResponse = new Subject();
  currentstandardSettingResponse = this.standardSettingResponse.asObservable();

  // New Subject for CPI and Side Note refresh
  private refreshCPIAndSideNote = new Subject<void>();
  refreshCPIAndSideNote$ = this.refreshCPIAndSideNote.asObservable();

  updateQueueDetailResponse(loanDetailResponse: any) {
    this.loadDetailResponse.next(loanDetailResponse);
  }

  updateStandardSettingResponse(standardSettingResponse: any) {
    this.standardSettingResponse.next(standardSettingResponse);
  }

  triggerRefreshCPIAndSideNote() {
    this.refreshCPIAndSideNote.next();
  }
}
